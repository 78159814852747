body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'prot-bold';
  src: url('./assests/fonts/protogroteskweb-bold.woff2') format('woff2'),
       url('./assests/fonts/protogroteskweb-bold.woff') format('woff');
  /* Add more src URLs for different formats if necessary */
}
@font-face {
  font-family: 'prot-light';
  src: url('./assests/fonts/protogroteskweb-light.woff2') format('woff2'),
       url('./assests/fonts/protogroteskweb-light.woff') format('woff');
  /* Add more src URLs for different formats if necessary */
}
@font-face {
  font-family: 'prot-extralight';
  src: url('./assests/fonts/protogroteskweb-extralight.woff2') format('woff2'),
       url('./assests/fonts/protogroteskweb-extralight.woff') format('woff');
  /* Add more src URLs for different formats if necessary */
}
@font-face {
  font-family: 'prot-regular';
  src: url('./assests/fonts/protogroteskweb-regular.woff2') format('woff2'),
       url('./assests/fonts/protogroteskweb-regular.woff') format('woff');
  /* Add more src URLs for different formats if necessary */
}