.section-what{
    display: flex;
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    background-color: #0d4e9d;
    position: relative;
    align-items: end;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-end;
    overflow: hidden;
    h1 {
        text-transform: uppercase;
    }
}