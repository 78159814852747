.register{
    height: 100vh;
    background-color: #e84223;
    color: white;
    &-logo{
        width: 22vw;
        @media screen and (max-width: 767px) {
            width: 75%;
        }
    }
    p{
        color: white;
    }
    &-desc{
        width: 31%;
        margin: auto;
        @media screen and (max-width:767px) {
            width: 90%;
        }
    }
    &-desc1{
        width: 20%;
        font-weight: bold;
        @media screen and (max-width:767px) {
            width: 90%;
        }
    }
    &-desc4{
        // width: 35%;
        padding-top: 1%;
        @media screen and (max-width:767px) {
            width: 90%;
        }
    }
    &-email{
        padding-top: 2%;
        a {
            font-size: 22px;
            font-weight: bold;
            color: white;
            &:hover{
                text-decoration: none;
                color: white;
            }
        }
    }

}