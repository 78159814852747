#contact-modal{
    background-color: transparent;
    border: none;
    color: white;
    font-family: 'prot-bold';
    font-size: 25px;
}
#cont-mod {
    border-radius: 10px;
    color: white;
    font-family: 'prot-bold';
    max-width: 1000px;
    .modal-content {
        // background-color: transparent;
        border-radius: 4%;

    background-color: #e84223;

    }
}
form{
    .form-group{
        input, textarea{
            border: 1px #fff solid;
            background-color: transparent;
            min-height: 50px;
            border-radius: 5px;
            color: white;
            width: 50%;
            font-family: 'prot-light';
            &::placeholder {
                color: white;
                font-family: 'prot-light';
              }
              @media screen and (max-width: 767px) {
                  width: 100%;
              }
        }
    }
    .btn-sub{
        background-color: #004361;
        color: #ffffff;
    }
}