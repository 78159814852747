.section-contact {
    background: #152232;
    min-height: 100vh;
    position: relative;
    @media only screen and (max-width: 767px){
        padding: 30px 0;
    }
    // .custom-container{
    //     @media only screen and (min-width: 1200px){
    //         max-width: 90%;
    //     }
    // }
    .contact-bolt{
        width: 8vw;
        // display: block;
        display: flex;
        position: relative;
        right: -25vw;
        // top: 50px;
        @media only screen and (max-width: 767px){
            width: 10vw;
            right: 0;
            margin: 0!important;
            margin-left: auto!important;
            
        }
    }
    .heading{
        font-size: 50px;
        text-align: left;
        color: #FAEF8A;
        font-weight: 700;
        display: flex;
        font-family: 'protogrotesk-bold';
        // @media only screen and (min-width: 1200px){
        //     font-size: 5vw;
        // }
        @media only screen and (max-width: 767px){
            font-size: 30px;
        }
    }
    .italic-sub-heading{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
        color: #F4F3ED;
        // @media only screen and (min-width: 1200px){
        //     font-size: 2vw;
        // }
        @media only screen and (max-width: 676px){
            font-size: 16px;
            font-weight: 600;
        }
    }
    .text{
        color: #F4F3ED;
        font-size: 18px;
        font-weight: 400;
        // @media only screen and (min-width: 1200px){
        //     font-size: 1.8vw;
        // }
        @media only screen and (max-width: 676px){
            font-size: 14px;
            font-weight: 400;
        }
    }
    .bottom-fix{
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        overflow: hidden;
    }
    .copyrigt-text{
        font-style: italic;
        color: #F4F3ED;
        font-size: 14px;
        margin-left: 20px;
        // @media only screen and (min-width: 1200px){
        //     font-size: 1.4vw;
        // }
        @media only screen and (max-width: 767px){
            font-size: 12px;
        }
    }
    .mail-text{
        font-size: 20px;
        @media only screen and (max-width: 767px){
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
    }
    .links{
        color: #F4F3ED;
        text-decoration: underline;
        font-size: 20px;
        font-weight: 400;
        // @media only screen and (min-width: 1200px){
        //     font-size: 2vw;
        // }
        @media only screen and (max-width: 767px){
            font-size: 14px;
            font-weight: 400;
        }
    }
    .social-links{
        float: right;
        margin-right: 20px;
        @media only screen and (max-width: 767px){
            font-size: 14px;
            margin: 0 0 30px 0;
            float: none;
            text-align: center;
        }
    }
    &-desc {
        display: contents;
        h1{
            font-family: 'prot-bold';
            span{
                font-family: 'prot-light';
                font-size: 25px;
                color: #89beda;
            }
        }
    }
    &-email{
        a {
            font-size: 22px;
            font-weight: bold;
            color: white;
            &:hover{
                text-decoration: none;
                color: white;
            }
        }
    }

    &-social {
    
        font-size: 22px;
            a{
                color: #f3ed83;
                text-decoration: none;
                &:hover{
                    color: white;
                }
            }
        
    }
    &-tradmark {
        background: #152232;
        color: white;
    }
}