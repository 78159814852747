.about{
    min-height: 100vh;
    // background-color: #0d4e9d;
    background-color: #e84223;
    color: white;
    font-family: "Inter", sans-serif;
    padding-top: 60px;
    @media only screen and (max-width: 767px){
        padding: 30px 0;
    }
    .about-cloud{
        width: 10vw;
        display: block;
        position: relative;
        right: -25vw;
        top: 50px;
        // transform: rotate(90deg);
        @media only screen and (max-width: 767px){
            top: 20px;
            right: -30vw;
        }
    }
    .sub-heading{
        font-size: 35px;
        font-weight: 700;
        color: #0d4e9d;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        @media only screen and (min-width: 1400px){
            font-size: 3vw;
        }
        @media only screen and (max-width: 767px){
            font-size: 24px;
        }
    }
    .navigation-box{
        text-align: center;
    }
    .navigation-link{
        color: #F4F3ED;;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        transition: 0.3s all ease;
        @media only screen and (min-width: 1400px){
            font-size: 2vw;
        }
         @media only screen and (max-width: 767px){
            font-size: 18px;
            font-weight: 600;;
         }
    }
    .navigation-link:hover{
        text-decoration: none;
        color: #0d4e9d;
    }
    &-logo{
        width: 50vw;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
            width: 75%;
        }
    }
    p{
        color: white;
    }
    &-desc1{
        width: 15%;
        font-weight: bold;
        @media screen and (max-width:767px) {
            width: 90%;
        }
    }
    &-desc2{
        width: 32%;
        padding-top: 1.5%;
        @media screen and (max-width:767px) {
            width: 90%;
        }
    }
    &-desc3{
        width: 35%;
        padding-top: 1.5%;
        @media screen and (max-width:767px) {
            width: 90%;
        }
    }
    &-desc4{
        // width: 35%;
        padding-top: 1.5%;
        @media screen and (max-width:767px) {
            width: 90%;
        }
    }
    &-email{
        padding-top: 5%;
        a {
            font-size: 22px;
            font-weight: bold;
            color: white;
            &:hover{
                text-decoration: none;
                color: white;
            }
        }
    }
}