    .home {
        display: flex;
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    background-color: #0d4e9d;
    position: relative;
    align-items: end;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-end;
    overflow: hidden;
    // border-bottom: 1px #fff solid;
    #dvd {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30vmin;
        height: 30vmin;
        background-image: url('../assests/fw.png');
        background-size: contain;
        animation: spin 10s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      &-register, &-about, &-contact{
          font-family: 'prot-bold';
          color: white;
          font-size: 25px;
      }

}