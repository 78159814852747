@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@font-face {
  font-family: 'protogrotesk-bold';
  src: 
    url('../assests/fonts/protogroteskweb-bold.woff2') format('woff2'),
    url('../assests/fonts/protogroteskweb-bold.woff') format('woff');
}

@font-face {
  font-family: 'protogrotesk-extralight';
  src: 
    url('../assests/fonts/protogroteskweb-extralight.woff2') format('woff2'),
    url('../assests/fonts/protogroteskweb-extralight.woff') format('woff');
}

@font-face {
  font-family: 'protogrotesk-light';
  src: 
    url('../assests/fonts/protogroteskweb-light.woff2') format('woff2'),
    url('../assests/fonts/protogroteskweb-light.woff') format('woff');
}

@font-face {
  font-family: 'protogrotesk-regular';
  src: 
    url('../assests/fonts/protogroteskweb-regular.woff2') format('woff2'),
    url('../assests/fonts/protogroteskweb-regular.woff') format('woff');
}

@import "section-home.scss";
@import "section-what.scss";
@import "section-about.scss";
@import "section-studio.scss";
@import "section-services.scss";
@import "section-register.scss";
@import "section-contact.scss";





@import "_main.scss";
